/**
 * Map module.
 *
 * @module components/Map
 *
 * @file Map.js
 *
 * Map Functionality
 *
 *
 * @class      Map
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class Map {
  constructor() {

    // Get the map element
    this.map = document.getElementById('map')

    this.token = 'pk.eyJ1IjoicGVhcG9kcyIsImEiOiJja3RkMnYxdDcwMThzMnpxcWN3MG9wZnF4In0.nU7IbTSZRUS_YrNSSe6_7g'
    this.style = 'mapbox://styles/peapods/cktd2xg850vz717n73wjej0rz'

    this.center = [0.85143, 52.95127]
    this.marker = [0.85143, 52.95127]
    this.zoom = 15

    // Quit if browser unable to handle map
    if (typeof mapboxgl == 'undefined' || !mapboxgl.supported()) {
      return
    }

    // Add map if element present on page
    if (this.map) this.setupMap()
  }

  setupMap() {

    mapboxgl.accessToken = this.token

    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      center: this.center,
      zoom: this.zoom,
      // attributionControl: false
    })

    // Add zoom and rotation controls to the map.
    this.map.addControl(new mapboxgl.NavigationControl())

    this.addMarker()

    this.touchEvents()
  }

  addMarker() {

    let marker1 = new mapboxgl.Marker({
      'color': '#79a7d3'
    })
      .setLngLat(this.marker)
      .addTo(this.map)
  }

  touchEvents() {

    // Disable scroll wheel zoom
    this.map.scrollZoom.disable()

    this.map.dragRotate.disable()
    this.map.touchPitch.disable()
    this.map.touchZoomRotate.disable()

    this.map.on('dragstart', (event) => {

      if (this.isTouchEvent(event) && !this.isTwoFingerTouch(event)) {

        this.map.dragPan.disable()
        this.map.touchZoomRotate.disable()
      }
    })

    // Drag events not emited after dragPan disabled, so I use touch event here
    this.map.on('touchstart', (event) => {

      if (this.isTouchEvent(event) && this.isTwoFingerTouch(event)) {

        this.map.dragPan.enable()
        this.map.touchZoomRotate.enable()
        this.map.touchZoomRotate.disableRotation()
      }
    })
  }

  isTouchEvent(e) {
    return e.originalEvent && 'touches' in e.originalEvent
  }

  isTwoFingerTouch(e) {
    return e.originalEvent.touches.length >= 2
  }
}