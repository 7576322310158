/**
 * Fix Heights module.
 *
 * @module components/FixHeights
 *
 * @file fix-heights.js
 *
 * Fix Heights Functionality
 *
 * Sets absolute pixel heights on elements to prevent page jumps on mobile
 *
 * @class      FixHeights
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class FixHeights {
  constructor() {
    let html = `
      <style type='text/css'>
        @media (orientation: portrait) {
          .ViewportHeight100 {
            height: ${window.innerHeight / 1.5}px;
          }
        }
        @media (orientation: landscape) {
          .ViewportHeight100 {
            height: ${window.innerHeight}px;
          }
        }
      </style>
    `

    document.querySelector('body').insertAdjacentHTML('beforeend', html)
  }
}
