/**
 * ResponsiveFixBg
 *
 * @module components/ResponsiveFixBg
 *
 * @file ResponsiveFixBg.js
 *
 * ResponsiResponsiveFixBgveBg Functionality
 *
 *
 * @class      ResponsiveFixBg
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class ResponsiveFixBg {
  constructor() {

    this.imageContainers = document.querySelectorAll('.responsive-fixed-image')
    if (this.imageContainers.length) this.init()
  }

  init() {
    this.imageContainers.forEach((element, index) => {
      let currElement = element
      let currImg = currElement.querySelector('img')
      this.insertImage(currImg, currElement)
      currImg.addEventListener('load', () => this.insertImage(currImg, currElement))
    })
  }

  insertImage(currImg, currElement) {
    let img = currImg
    let src = img.currentSrc ? img.currentSrc : img.src
    currElement.style.backgroundImage = `url('${src}')`
  }
}
