/**
 * Validate Enquiry Form module.
 *
 * @module components/ValidateEnquiryForm
 *
 * @file validateEnquiryForm.js
 *
 * Validate Enquiry Form Functionality
 * Resets the date field custom validity message on input
 *
 *
 * @class      ValidateEnquiryForm
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class ValidateEnquiryForm {
  constructor() {

    this.fields = document.querySelectorAll('.date-wrapper input[type="date"]')

    this.fields.forEach((field) => {

      // On input, set Custom Validity to empty string
      field.addEventListener('input', (event) => {
        event.target.setCustomValidity('')
      })
    })
  }
}