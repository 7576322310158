/**
 * InputFocus module.
 *
 * @module components/InputFocus
 *
 * @file InputFocus.js
 *
 * InputFocus Functionality
 *
 *
 * @class      InputFocus
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class InputFocus {
  constructor() {

    this.document = document
    this.header = document.querySelector('header')

    // Event handler for focus on input elements
    document.querySelectorAll('input:not([type="submit"])').forEach((el) => {
      el.addEventListener('focus', (el) => this.checkFocus(el))
    })
  }

  // Check if focused element is under the header
  checkFocus(el) {

    var input = el.currentTarget

    // Get the bounding rectangle of the input element
    let rect = input.getBoundingClientRect()

    // Check if focused element under or above the header
    if (rect.top < this.header.offsetHeight) {

      // Scroll into view
      window.scrollTo(0, window.scrollY - this.header.offsetHeight - 50)
    }
  }
}