/**
 * Old Barnyard Cottage javascript module.
 *
 * @module main
 *
 * @file main.js
 *
 * Initiates the necessary javascript
 *
 * Imports the Init module and runs the plugin.
 *
 * @class      OldBarnyardCottage
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */
import { Init } from './components/init.js';

class OldBarnyardCottage {
  constructor() {
    let init = new Init();
  }
}
window.addEventListener('load', function(event) {
  const oldBarnyardCottage = new OldBarnyardCottage();
});