/**
 * Testimonials module.
 *
 * @module components/Testimonials
 *
 * @file Testimonials.js
 *
 * Testimonials Functionality
 *
 *
 * @class      Testimonials
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */
import Flickity from 'flickity'

export class Testimonials {
  constructor() {

    if (!document.querySelector('.reviews-carousel')) return

    new Flickity('.reviews-carousel', {
      wrapAround: true,
      autoPlay: 7000,
      dragThreshold: 10,
      pageDots: true,
      selectedAttraction: 0.02,
      prevNextButtons: false,
      pauseAutoPlayOnHover: false
    })
  }
}