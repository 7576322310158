/**
 * Toggle module.
 *
 * @module components/Toggle
 *
 * @file Toggle.js
 *
 * Toggle Functionality
 *
 *
 * @class      Toggle
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class Toggle {
  constructor() {

    this.toggleButtons = document.querySelectorAll('.toggle-link')
    this.toggleActiveClass = 'is-active'

    this.init()
  }

  init() {
    if (!this.toggleButtons.length) return false

    this.toggleButtons.forEach(button => {
      button.addEventListener('click', event => this.toggleToggle(event))

      // Get the next sibling
      let target = button.nextElementSibling

      // Set the data-height attribute to the height of the target
      target.setAttribute('data-height', target.scrollHeight + 'px')
    })
  }

  toggleToggle(event) {
    let el = event.currentTarget

    if (el.classList.contains(this.toggleActiveClass)) {
      this.functionClose(el)
    } else {
      this.functionOpen(el)
    }
  }

  functionOpen(trigger) {

    trigger.setAttribute('aria-expanded', true)
    trigger.classList.add('is-active')

    let target = trigger.nextElementSibling

    target.setAttribute('aria-hidden', 'false')
    target.style.maxHeight = target.getAttribute('data-height')
  }

  functionClose(trigger) {

    trigger.setAttribute('aria-expanded', false)
    trigger.classList.remove('is-active')

    let target = trigger.nextElementSibling

    target.setAttribute('aria-hidden', 'true')
    target.style.maxHeight = '0px'
  }
}
