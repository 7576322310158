/**
 * Init module.
 *
 * @module components/init
 * @see module:main
 *
 * @file init.js
 *
 * Initializes the scripts
 *
 * Imports the javascript modules.
 * Creates new instances of the theme classes.
 *
 * @class      Init
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */
import Common from './common.js'
import { Loader } from './loader.js'
import { Toggle } from './toggle.js'
import { Keyboard } from './keyboard.js'
import { ActiveLinks } from './active-links.js'
import { Testimonials } from './testimonials.js'
import { ResponsiveFixBg } from './responsive-fix-bg.js'
import { DisableCookies } from './disable-cookies.js'
import { Hamburger } from './hamburger.js'
import { FixHeights } from './fix-heights.js'
import { Map } from './map.js'
import { ValidateEnquiryForm } from './validateEnquiryForm.js'
import { InputFocus } from './input-focus.js'
import 'altcha'

export class Init {
  constructor() {

    new DisableCookies()

    // Initialize common variables
    Common.init()

    // Output initialisation message
    if (Common.consoleLogging) console.log('Initializing Old Barnyard Cottage Theme Script')

    new Hamburger()
    new Loader()
    new Toggle()
    new Keyboard()
    new ActiveLinks()
    new Testimonials()
    new ResponsiveFixBg()
    new FixHeights()
    new Map()
    new InputFocus()
    new ValidateEnquiryForm()
  }
}