/**
 * Keyboard module.
 *
 * @module components/Keyboard
 *
 * @file Keyboard.js
 *
 * Keyboard Functionality
 *
 *
 * @class      Keyboard
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class Keyboard {
  constructor() {

    document.body.addEventListener('mousedown', function () {
      document.body.classList.add('using-mouse')
      document.body.classList.remove('using-keyboard')
    })

    document.body.addEventListener('keydown', function () {
      document.body.classList.remove('using-mouse')
      document.body.classList.add('using-keyboard')
    })
  }
}