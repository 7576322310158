/**
 * Active Links module.
 *
 * @module components/Active Links
 *
 * @file active-links.js
 *
 * Active Links Functionality
 * Checks the current path and sets the active class on the corresponding nav link
 *
 * @class      ActiveLinks
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class ActiveLinks {
  constructor() {

    this.activeLinks = document.querySelectorAll('.nav-link a')
    this.activeClass = 'is-active'

    this.currentPath = location.host + location.pathname

    this.checkLinks()
  }

  checkLinks() {
    this.activeLinks.forEach((element) => {

      // if the current path is like this link, make it active
      if (element.getAttribute('href').endsWith(this.currentPath)) this.setActive(element)
    })
  }

  setActive(element) {
    element.classList.add(this.activeClass)
    element.parentElement.classList.add(this.activeClass)
  }
}
