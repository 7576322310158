/**
 * Loader module.
 *
 * @module components/Loader
 *
 * @file loader.js
 *
 * Loader Functionality
 *
 *
 * @class      Loader
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class Loader {
  constructor() {
    this.wrapperClass = '.wrapper'
    this.loader = document.getElementById('load-screen')
    this.firstImg = document.querySelector(this.wrapperClass + ' img')
    if (this.loader) {
      this.init()
    }
  }

  init() {
    if (!this.firstImg.complete) {
      this.firstImg.addEventListener('load', this.loaderKill.bind(this))
      this.firstImg.addEventListener('error', this.loaderKill.bind(this))
    } else {
      this.loaderKill()
    }
  }

  loaderKill() {
    this.firstImg.removeEventListener('load', this.loaderKill.bind(this))
    this.loader.style.display = 'none'
    const fadeUpPostLoader = document.querySelectorAll('.fade-up-post-loader')
    fadeUpPostLoader.forEach((element) => {
      element.classList.add('animate-appear')
    })
  }
}

