/**
 * Hamburger module.
 *
 * @module components/Hamburger
 *
 * @file Hamburger.js
 *
 * Hamburger Functionality
 *
 *
 * @class      Hamburger
 * @package    oldbarnyardcottage
 * @subpackage oldbarnyardcottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class Hamburger {
  constructor() {
    this.burgerState = false
    this.burger = document.getElementById('burger-icon')
    this.mobileNav = document.getElementById('dropdown-nav')
    this.mediaQuery = window.matchMedia('(min-width: 1024px)')

    this.init()
  }

  init() {
    if (!this.burger) return false

    // Toggle burger state on click
    this.burger.addEventListener('click', () => this.burgerToggle())

    // Hide drop down mobile menu on any link click
    document.querySelectorAll('body a').forEach(link => {
      link.addEventListener('click', () => this.burgerDeactivate())
    })

    // Hide drop down nav when window resized across mobile boundary
    this.mediaQuery.addEventListener('change', () => this.burgerDeactivate())
  }

  // Toggle burger state on click
  burgerToggle() {
    this.burgerState ? this.burgerDeactivate() : this.burgerActivate()
    return false
  }

  // Add classes and set ARIA attributes
  burgerActivate() {
    this.burgerState = true
    window.ontouchmove = function (e) { e.preventDefault() }
    this.burger.setAttribute('aria-expanded', true)
    this.burger.classList.add('is-active')
    this.mobileNav.classList.add('is-active')
  }

  // Remove classes and set ARIA attributes
  burgerDeactivate() {
    this.burgerState = false
    window.ontouchmove = null
    this.burger.setAttribute('aria-expanded', false)
    this.burger.classList.remove('is-active')
    this.mobileNav.classList.remove('is-active')
  }
}
